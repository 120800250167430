import axios from "axios";
import appConfig from "configs/app.config";
import ApiService from "./ApiService";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "/v1/campaign-portal/v1/campaign-portal/sign-in",
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/v1/campaign-portal/sign-up",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/v1/campaign-portal/sign-out",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/v1/campaign-portal/forgot-password",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/v1/campaign-portal/reset-password",
    method: "post",
    data,
  });
}

export async function apiNewSignIn(data) {
  return axios({
    url: appConfig.apiPrefix+"/v1/campaign-portal/auth/token-login",
    method: "post",
    headers: { Authorization: `Bearer ${data}` },
  });
}
